const colors = {
  lpHeaderScrollColor: '#FDDC15',
  lpBodyColorGradient:
    'linear-gradient(180deg, #FDDC15 0%, #FDDC15 13.54%, #FDDC15 55.73%);',
  lpBannerTitleBoldColor: '#033B6C',
  lpFooterColor: '#033B6C',
  lpBannerTitleColor: '#ffffff',
  lpBannerPrizeColor: '#033B6C',
  lbHeaderTextColor: '#033B6C',
};

const fonts = {
  headerLinks: {
    fontFamily: 'mainfont',
    fontWeight: 400,
    color: 'black',
  },
};

const components = {
  Link: {
    variants: {
      //LandingPage Header Links
      lpHeaderLinks: {
        fontFamily: 'mainfont',
        fontWeight: 400,
        display: { base: 'none', md: 'flex' },
        _hover: { textDecor: 'underline' },
        color: 'black',
      },
      lpFooterLinks: {
        fontSize: ['20px', '30px'],
        fontWeight: 700,
        color: 'white',
        fontFamily: 'mainfont',
        _hover: { textDecor: 'underline' },
      },
    },
  },
  Heading: {
    variants: {
      lpBannerTitle: {
        color: '#ffffff',
        letterSpacing: '1px',
        
        fontWeight: '900',
        fontSize: ['29px', '18px', '30px', '38px', '45px', '53px'],
        textAlign: 'center',
        fontFamily: 'mainfont',
      },
      lpBannerTitle2: {
        color: '#033B6C',
      },
    },
  },
  Text: {
    variants: {
      lpDescriptionInfos: {
        w: '98%',
        h: 'full',
        opacity: '80%',
        textAlign: ['justify', 'center'],
        fontSize: ['17px', '10px', '14px', '17px', '21px', '26px'],
        color: '#1D1D1D',
        fontFamily: 'Raleway',
        fontWeight: 400,
        lineHeight: '140%',
      },
      lpBannerTitle: {
        fontFamily: 'mainfont',
        textAlign: 'center',
        fontSize: ['30px', '45px'],
        color: 'lpBannerTitleColor',
        fontWeight: 700,
        lineHeight: '100%',
      },
      lpBannerTitle2: {
        color: '#033B6C',
      },
      lpFooterText: {
        fontWeight: 400,
        fontFamily: 'Inter',
        color: 'white',
        fontSize: ['8px', '8px', '8px', '10px', '12px'],
        textAlign: ['justify', 'center'],
      },
    },
  },
  Button: {
    variants: {
      lpHeaderButton: {
        borderRadius: 60,
        pl: 10,
        pr: 10,
        bgColor: '#FFFFFF',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        fontFamily: 'mainfont',
        fontSize: '18px',
        fontWeight: 700,
        color: '#000000',
      },
      lpBannerButton: {
        fontWeight: 700,
        fontFamily: 'mainfont',
        lineHeight: '30.48px',
        color: '#FFFFFF',
        fontSize: ['17px', '13px', '17px', '17px', '21px', '26px'],
        bgColor: '#033B6C',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        pl: ['8', '9'],
        pt: ['4', '5'],
        pb: ['4', '5'],
        pr: ['8', '9'],
        borderRadius: '43.5px',
      },
      lpLoginButton: {
        fontFamily: 'mainfont',
        borderWidth: 'hex',
        borderColor: '#1D1D1D',
        border: '1px solid black',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        outline: '2px solid transparent',
        outlineOffset: '2px',
        color: '#1D1D1D',
      },
    },
  },
};

export { colors, fonts, components };
